<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Clicks
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								dw-date-time-picker(v-model="filters.datePicker")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
								.form-group
									label Status
									select.form-control(v-model="filters.status", @change="loadData()")
										option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
								.form-group.fg-placement
									label Placement(s)
									multiselect(:multiple="true", :options="placementOptions", v-model="filters.placement_id",
										:limit="1", group-label="name", group-values="items", :group-select="false",
										track-by="id", label="name", deselect-label="", select-label="", @search-change="searchPlacement",
										:loading="placementOptionsLoading", :internal-search="false")
								select-publisher(v-model="filters.publisher_id")
								select-advertiser(v-model="filters.advertiser_id")
								.form-group.fg-offer
									label Offer(s)
									multiselect(:multiple="true", :options="offerOptions", v-model="filters.offer_id",
										track-by="id", label="t", deselect-label="", select-label="",
										:internal-search="true")
								.form-group.no-label
									label.form-check-label
									b-checkbox(type="checkbox", v-model="filters.is_conversion") Conversions
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go

				p.clearfix &nbsp;

				paginate(:paginator="paginate", @update-page-size="loadData")

				table.table.table-bordered
					thead
						tr
							th ID
							th Advertiser
							th Offer
							th Plac.
							th Pub.
							th Sub1 / Sub2
							th Query Params
							th Headers
							th Created At
							th Actions
					tbody
						tr(v-if="records.length===0")
							td(colspan="12") No matching records were found
						tr(v-for="r in records", :key="r.id")
							td(style="font-size: 10px") {{ r.id }}
							td
								entity(:id="r.advertiser.id", :name="r.advertiser.name", type="advertiser")
							td
								entity(:id="r.offer.id", :name="r.offer.name", type="offer")
							td
								entity(:id="r.placement.id", :name="r.placement.name", type="placement")
							td
								entity(:id="r.publisher.id", :name="r.publisher.name", type="publisher")
							td
								div {{ r.params.sub1 }}
								div {{ r.params.sub2 }}
								div {{ r.adv_sub1 }}
								//entity(v-if="r.sub1", :id="r.sub1.id", :name="r.click.sub1.name", type="subsource")
								//entity(v-if="r.adv_sub1", :id="r.click.adv_sub1.id", :name="r.click.adv_sub1.name", type="subsource")

							td
								pre.toggleable(:class="{'limit-height': !r._expand}") {{ r.params }}
									a.toggle-expand(href="javascript: void(0);", @click="r._expand = !r._expand") ...
							td
								pre.toggleable(:class="{'limit-height': !r._expand}") {{ r.headers }}
									a.toggle-expand(href="javascript: void(0);", @click="r._expand = !r._expand") ...
							td {{ r.created_at }}
							td.actions
								//router-link.btn.btn-action.btn-secondary(:to="{name:'postback-log-view', params: {id:r.id}}")
									i.la.la-pen
								//.btn.btn-action.btn-outline-danger()
									i.la.la-trash

				paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
pre {
	background: #f0f0f0;
	padding: 5px;
	border-radius: 3px;
	font-size: 8px;
}
pre.toggleable {
	width: 280px;
	overflow: auto;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import Vue from "vue";
export default {
	name: 'Events',
	async beforeRouteEnter(to, from, next){
		let dateStartQ = to.query.date_start;

		let offerOptions = await Vue.$dwData.offer.getOptions(true);

		let dateStartObj = null;
		if (dateStartQ){
			dateStartObj = moment(dateStartQ, 'YYYY-MM-DD HH:mm');
		}
		if (!dateStartObj || !dateStartObj.isValid()){
			dateStartObj = moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm');
		}
		let dateEndQ = to.query.date_end;

		let dateEndObj = null;
		if (dateEndQ){
			dateEndObj = moment(dateEndQ, 'YYYY-MM-DD HH:mm');
		}
		if (!dateEndObj || !dateEndObj.isValid()){
			dateEndObj = moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm');
		}

		let advertiserOptions = await Vue.$dwData.advertiser.getOptions(true);
		let selectedAdvertiserOptions = Vue.$dwData.common.getSelectedFromString(advertiserOptions, to.query.advertiser_id);

		let publisherOptions = await Vue.$dwData.publisher.getOptions(true);
		let selectedPublisherOptions = Vue.$dwData.common.getSelectedFromString(publisherOptions, to.query.publisher_id);

		let selectedOfferOptions = await Vue.$dwData.offer.getSelectedOptions(to.query.offer_id);
		let selectedPlacementOptions = await Vue.$dwData.placement.getSelectedOptions(to.query.placement_id);

		next(async (vm) => {
			vm.offerOptions = offerOptions;
			vm.advertiserOptions = advertiserOptions;
			vm.publisherOptions = publisherOptions;
			vm.filters.offer_id = selectedOfferOptions;
			vm.filters.publisher_id = selectedPublisherOptions;
			vm.filters.placement_id = selectedPlacementOptions;
			vm.filters.advertiser_id = selectedAdvertiserOptions;
			vm.filters.datePicker.dateRange.startDate = dateStartObj;
			vm.filters.datePicker.dateRange.endDate = dateEndObj;

			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			// PERMS: null,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			advertiserOptions: [],
			publisherOptions: [],
			offerOptions: [],
			placementOptions: [],
			placementOptionsLoading: false,
			offerOptionsLoading: false,
			filters: {
				keyword: '',
				status: 1,
				is_conversion: false,
				offer_id: [],
				placement_id: [],
				publisher_id: [],
				advertiser_id: [],
				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: null,
						endDate: null
					},
				}
			},
			statusOptions: [
				{v: undefined, t: 'All'},
				{v: 0, t: 'Inactive'},
				{v: 1, t: 'Active Only'},
			],
			records: [],
			filtersExpanded: false,
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Media' },
			{ title: 'Reports' },
			{ title: 'Clicks' }
		]);
	},
	methods: {

		selectOffersFilter(){
			// let selectedOffers = this.filters.offer_id;
			// this.$modal.show(SelectOffersModal,
			// 	{
			// 		selectedOffers
			// 	},
			// 	{
			// 		height: '90%',
			// 		width: '90%',
			// 		minWidth: 500
			// 		//maxWidth: '100%',
			// 	},
			// 	{
			// 		'before-close': (/*event*/) => {
			// 			Vue.set(this.filters, 'offer_id', selectedOffers);
			// 		}
			// 	}
			// );
		},

		async searchPlacement(query){
			this.placementOptionsLoading = true;
			try {
				this.placementOptions = await Vue.$dwData.placement.getOptions({keyword: query});
			} catch (e) {
				console.error(e);
			}
			this.placementOptionsLoading = false;
		},

		// async searchOffer(query){
		// 	console.log(query);
		// 	this.offerOptionsLoading = true;
		// 	try {
		// 		this.offerOptions = await Vue.$dwData.offer.getOptions({keyword: query});
		// 	} catch (e) {
		// 		console.error(e);
		// 	}
		// 	this.offerOptionsLoading = false;
		// },

		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				status: this.filters.status,
				is_conversion: this.filters.is_conversion ? 1 : 0,
				date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
				advertiser_id: this.filters.advertiser_id.map(r => r.v).join(','),
				publisher_id: this.filters.publisher_id.map(r => r.v).join(','),
				offer_id: this.filters.offer_id.map(r => r.id).join(','),
				placement_id: this.filters.placement_id.map(r => r.id).join(','),
			};
			try {
				let resp = await this.$api.get('/clicks/getList', {params});
				this.records = resp.records;
				this.records.forEach(r => {
					Vue.set(r, '_expand', false);
				});
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	}

}
</script>
